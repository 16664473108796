import React from "react"
import { graphql, Link } from "gatsby";
import { Container } from "react-bootstrap";
import Header from "../components/common/header";
import Footer from "../components/common/footer";
import Table from 'react-bootstrap/Table'
import "./../styles/style.scss";

class TagsPage extends React.Component {

    render() {
        const tags = this.props.data.allMarkdownRemark.group;
        return (
            <div>
                <Header pageName={"Tags"} />
                <Container>
                    <div className={"section-title"}>
                        <h2>Tags</h2>
                    </div>
                    <Table bordered responsive className={"tags-table"}>
                        <thead>
                            <tr>
                                <th>Tag</th>
                                <th># of posts</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tags.map((tag, index) => {
                                return (
                                    <tr key={tag.fieldValue}>
                                        <td><Link to={"/tags/" + tag.fieldValue.toString().toLowerCase()}>{tag.fieldValue}</Link></td>
                                        <td>{tag.totalCount}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default TagsPage;


export const pageQuery = graphql`
query {
    site {
        siteMetadata {
          title
        }
    }
    allMarkdownRemark(limit: 2000) {
        group(field: frontmatter___tags) {
            fieldValue
            totalCount
        }
    }
}
`
